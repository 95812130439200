<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="List"
                :showBtn="true"
                :showAddNew="false"
                title="Add Purchase Bill"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row mt-2 gy-1">
                <div class="col-md-4">
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
                <div class="col-md-4">
                    <v-select
                        placeholder="Select Business"
                        v-model="formData.business_id"
                        :options="business"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-between">
                    <AsyncSelect
                        placeholder="Select Contact"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :format-label="formatPatientLabel"
                        class="flex-grow-1"
                        :additional-query="{type: 'supplier'}"
                    />
                    <span class="filter-search-icon cursor-pointer mx-1" @click="onOpenContactSearchModal">
                      <i class="fas fa-search"></i>
                    </span>
                </div>
                <div class="col-md-4">
                    <v-select
                        placeholder="Account Head"
                        v-model="formData.account_head_id"
                        :options="accountPayable"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>

                <div class="col-md-4">
                  <v-select
                      placeholder="Select Warehouse Location"
                      v-model="formData.location_id"
                      :options="locations"
                      label="text"
                      :reduce="text => text.id"
                  />
                </div>
                <div class="col-md-4">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <div class="input-group input-group-merge invoice-edit-input-group">
                            <div class="input-group-text">
                                <span>{{ prefix }}-</span>
                            </div>
                            <input type="number" min="1" class="form-control invoice-edit-input" placeholder="" v-model="serial">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="px-2">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
                </li>
                <li class="nav-item">
                    <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link" data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
                </li>
            </ul>
        </div>

        <div class="px-3">
            <Bill
                class="mt-2"
                v-for="(data, index) in accountsDetails"
                :key="index"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                @onClose="removeBill"
                @onClickToApplyTdsVds="() => onClickToApplyTdsVds(data)"
            />
            <Bill
                class="mt-2"
                v-for="(data, index) in itemsDetails"
                :key="index"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                @onClose="removeBill"
                @onClickToApplyTdsVds="() => onClickToApplyTdsVds(data)"
            />
        </div>

        <div class="px-2 mt-2">
            <button @click="addNewBill" class="btn btn-primary">Add line</button>
        </div>

        <div class="px-2">
            <div class="row justify-content-end">
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Sub total</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model="subTotal"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">VAT</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model="totalVAT"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="first-name">Total Amount</label>
                                </div>
                                <div class="col-sm-8">
                                    <input
                                        v-model="total"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="discount-amount">Adjustment Amount</label>
                                </div>
                                <div class="col-sm-8">
                                  <input
                                    id="discount-amount"
                                    v-model.trim="discountAmount"
                                    type="number" class="form-control form-control-sm discount-adj ml-auto text-right"
                                    placeholder="amount"
                                    :min="0"
                                  >
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="discountAmount > 0">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="discount-amount">Settlement Account</label>
                                </div>
                                <div class="col-sm-8">
                                  <v-select
                                    placeholder="Select Discount Head"
                                    v-model="formData.discount_head_id"
                                    :options="salesAccountHeads"
                                    label="name"
                                    :reduce="name => name.id"
                                  />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-4">
                                    <label class="col-form-label" for="discount-amount">Net Bill</label>
                                </div>
                                <div class="col-sm-8 text-right px-2">
                                  {{grandTotal ?? 0}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 px-2">
            <div class="mb-1">
                <label class="form-label" for="description">Memo</label>
                <vField
                    as="textarea"
                    name="description"
                    type="number"
                    class="form-control"
                />
            </div>
        </div>

        <div class="pb-5 px-2 mt-4">
            <div class="d-flex flex-wrap gap-1 gy-2">
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">Save</button>
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)" class="btn btn-primary">Save & New</button>
                <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
            </div>
        </div>
         <GlobalLoader />

        <ContactDropdownFilter
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            type="supplier"
            :companyRoles="companyRoles"
        />

        <ApplyTdsVdsModal
            v-if="store.state.isModalOpen && isItem"
            :data="itemDetails"
            :tdsRateList="tdsRateList"
            :vdsRateList="vdsRateList"
            @onSaveTdsVds="onSaveTdsVds"
            :tdsPayableHeads="tdsPayableHeads"
            :vdsPayableHeads="vdsPayableHeads"
        />

        <ApplyTdsVdsHeadModal
            v-if="store.state.isModalOpen && !isItem"
            :data="accountDetails"
            :tdsRateList="tdsRateList"
            :vdsRateList="vdsRateList"
            @onSaveTdsVds="onSaveTdsVds"
            :tdsPayableHeads="tdsPayableHeads"
            :vdsPayableHeads="vdsPayableHeads"
            :accountHeads="accountHeads"
        />
    </div>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {vatRate} from '@/data/inventory.js'
import {useRoute, useRouter} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'
import Bill from '@/components/molecule/company/inventory/purchase/Bill.vue'

import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import {useStore} from "vuex";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import handleRole from "@/services/modules/role";
import handleTdsVds from '@/services/modules/procurement/tdsVds';
import ApplyTdsVdsModal from "@/components/molecule/company/inventory/purchase/ApplyTdsVdsModal.vue";
import ApplyTdsVdsHeadModal from "@/components/molecule/company/inventory/purchase/ApplyTdsVdsHeadModal.vue";

const router = useRouter()
const route = useRoute()
const store = useStore()

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchAccountHead, ...rest} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchCompanyDefaultRoles } = handleRole();
const { fetchTdsRateList, fetchVdsRateList } = handleTdsVds();

const companyId = computed(() => {
    return route.params.companyId
})
let loader =ref(false)
let productLoader =ref(false)
let saveButtonLoader=ref(false)
let saveNewButtonLoader =ref(false)
let products = ref([])
let accountHeads = ref([])
let contacts = ref([])
let business = ref([])
let locations = ref([])
let salesAccountHeads = ref([])
let accountPayable = ref([])
let prefix = ref('')
let serial = ref('')
const itemDetails = ref(null);
const accountDetails = ref([]);
const tdsRateList = ref([]);
const vdsRateList = ref([]);
const discountAmount = ref(0);
const contactProfile = ref(null);
const companyRoles = ref([]);

let formData = ref({
    company_id: companyId,
    contact_profile_id: null,
    account_head_id: null,
    business_id: null,
    location_id: null,
    bill_number: '',
    status: 'active',
    date: '',
    has_item_detail: true,
    description: '',
    account_details: [],
    item_details: [
        {
            product_id: null,
            quantity: '',
            rate: '',
            vat: 0,
            vat_amount: 0,
            description: ""
        }
    ],
})

const tdsPayableHeads = ref([]);
const vdsPayableHeads = ref([]);

onMounted(async () => {
    loader.value=true
    let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=bill_journal`);
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
    formData.value.date =  new Date().toISOString().split('T')[0]
    // const productQuery = `?company_id=${companyId.value}&product_type=finished_goods`
    const companyQuery = `?company_id=${companyId.value}`
    
    const accountHeadRes = fetchAccountHead(companyQuery)
    const businessRes = fetchBusinessList(companyQuery)
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    const payableRes = rest.fetchAccountPayable(companyQuery)
    const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery)
    Promise.all([
        accountHeadRes.then(res=> {
            if(res.data) accountHeads.value = res.data
        }),
        businessRes.then(res=> {
            if(res.data) business.value = res.data
        }),
        businessLocationRes.then(res=> {
            if(res.data) locations.value = res.data
        }),
        fetchCompanyDefaultRoles(companyQuery).then(res => {
            if(res.data) companyRoles.value = res.data
        }),
        payableRes.then(res=> {
            if(res.data) {
                accountPayable.value = res.data
            }
            if(res.data?.length) {
                formData.value.account_head_id = res.data[0].id
            }
        }),
        salesAccountHeadsRes.then(res => {
          if(res.data) salesAccountHeads.value = res.data
        }),
        getTdsRates(),
        getVdsRates(),
        getAccountHeads("tds_payable"),
        getAccountHeads("vds_payable")
    ])
    .then(() => {loader.value=false})
    .catch(()=>{loader.value=false})
})

const getAccountHeads = async (slug) => {
    await rest.getAccountHeadBySlag(slug, getQuery()).then(res => {
        if(! res.status) return;
        setAccountHeads(res.data, slug);
    })
}

const setAccountHeads = (data, slug) => {
    if(slug === 'tds_payable') {
        tdsPayableHeads.value = data;
    }

    if(slug === 'vds_payable') {
        vdsPayableHeads.value = data;
    }
}

const onClickToApplyTdsVds = (item) => {

    if(isItem.value) {
        itemDetails.value = item;
    } else {
        accountDetails.value = item;
    }
    
    store.state.isModalOpen = true;
}

const onSaveTdsVds = (data) => {
    if(isItem.value) {
        for(let key in data) {
            itemDetails.value[key] = data[key];
        }
        return;
    }
    for(let key in data) {
        accountDetails.value[key] = data[key];
    }
}

const hasItemDetails = computed(() => {
    return formData.value.has_item_detail
})
const itemsDetails = computed(() => {
    return formData.value.account_details
})
const accountsDetails = computed(() => {
    return formData.value.item_details
})

const isItem = computed(() => {
    return formData.value.has_item_detail
})
const selectionText = computed(() => {
    return formData.value.has_item_detail ? 'Item details' : 'Accounting Details'
})

const subTotal = computed(() => {
    let subTotal=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            subTotal += parseInt((item.quantity * item.rate) - (item.discount_amount ?? 0))
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if(item.amount) {
                subTotal += (parseInt(item.amount) - (item.discount_amount ?? 0))
            }
        })
    }

    return subTotal
})

const totalVAT = computed(() => {
    let vat=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }

    return vat.toFixed(2)
})

const total = computed(() => {
    return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

const grandTotal = computed(() => total.value - discountAmount.value)

watch(discountAmount, () => {
  formData.value.adjustment_amount = discountAmount.value
})

watch(hasItemDetails, (newValue) => {
    if(!newValue) {
        delete formData.value.item_details
        formData.value.account_details = [{
            account_head_id: null,
            amount: '',
            vat: 0,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null
        }]
    }
    if(newValue) {
        delete formData.value.account_details
        formData.value.item_details = [{
            product_id: null,
            quantity: '',
            rate: '',
            vat: 0,
            vat_amount: 0,
            description: ""
        }]
    }
})

watch(contactProfile, (newVal) => {
    if(!newVal) {
        formData.value.contact_profile_id = null;
        return ;
    }

    formData.value.contact_profile_id = newVal.id;
})

const getQuery = () => {
    let query = '?company_id=' + companyId.value;
    return query;
}

const getTdsRates = async () => {
    let query =  getQuery();

    await fetchTdsRateList(query).then(res => {
        if(res.status){
            tdsRateList.value = res.data;
        }
    }).catch(err => {
        console.log(err)
    })
}

const getVdsRates = async () => {

    let query =  getQuery();

    await fetchVdsRateList(query).then(res => {
        if(res.status){
            vdsRateList.value = res.data;
        }
    }).catch(err => {
        console.log(err)
    })
}

function navigateToListPage() {
    router.push({name: 'purchase-list', params: route.params, query: route.query})
}

function onOpenContactSearchModal () {
    store.state.isModalOpenThree = true;
}

function onSearchContact (contact) {
    contactProfile.value = contact
}

function removeBill(index) {
    if(hasItemDetails.value) {
        formData.value.item_details.splice(index, 1)
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.splice(index, 1)
    }
}

function addNewBill() {
    if(hasItemDetails.value) {
        formData.value.item_details.push({
            product_id: null,
            quantity: '',
            rate: '',
            vat: 0,
            vat_amount: 0,
            description: ''
        })
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.push({
            account_head_id: null,
            amount: '',
            vat: 0,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null
        })
    }
}
function handleSubmit(redirect=false) {
    if(redirect) {
        saveButtonLoader.value = true
    } else {
        saveNewButtonLoader.value = true
    }
    formData.value.bill_number = prefix.value+'-'+serial.value
    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
    if(isItem.value) {
        delete copyFormData.account_details
        copyFormData.item_details = formData.value.item_details
    }else {
         delete copyFormData.item_details
        copyFormData.account_details = formData.value.account_details
    }

    rest.storeBill(copyFormData)
    .then(res => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
        if(res.status) {
            showSuccess(res.message)
            if(redirect) navigateToListPage()
            resetForm()
        }
        if(!res.status) {
            showError(res.message)
        }
    })
    .catch( () => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
        productLoader.value = false
    })
}

// have to refactor
function resetForm() {
    if(!isItem.value) {
        formData.value.account_details = [{
            account_head_id: null,
            amount: '',
            vat: 0,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null
        }]
    }
    if(isItem.value) {
        formData.value.item_details = [{
            product_id: null,
            quantity: '',
            rate: '',
            vat: 0,
            vat_amount: 0,
            description: ""
        }]
    }
}

</script>
